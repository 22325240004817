<template>
  <div class="md-category-page">
    <div class="md-cate-box">
      <div class="md-cate-panel">
        <div class="class-search">
          <div class="all"> <img src="../assets/img/course-title2.png" alt="">
            <div>全部课程</div>
          </div>

          <div class="search-box">
            <!-- <el-input placeholder="搜索感兴趣的课程内容" v-model="params.searchValue" clearable @clear="getVideoListByCategoryId(1)"
              class="input-with-select">
              <el-button slot="append" @click="getVideoListByCategoryId(1)"> 搜索</el-button>
            </el-input> -->
            <el-input placeholder="搜索感兴趣的课程内容..." class="search-input" v-model="params.searchValue"
              @clear="getVideoListByCategoryId(1)">
              <i slot="suffix" class="el-icon-search" @click="getVideoListByCategoryId(1)"> </i>
            </el-input>
          </div>
        </div>
        <div class="type-box">
          <div class="type-list">
            <b>方向：</b>
            <span :class="['type-item', +pIndex === item.rid ? 'type-item-active' : '']"
              v-for="(item, index) in categoryAryList" :key="index" @click="handleCategory(item)">{{ item.name }}</span>
          </div>
          <div class="type-list">
            <b>分类：</b>
            <span :class="['type-item', +cIndex === c.rid ? 'type-item-active' : '']"
              v-for="(c, i) in categoryItemInfo.categoryList" :key="i" @click="handleCategoryItem(c)">{{ c.name }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="md-class-con">
      <div class="md-class-container">
        <ul class="md-class-list" v-if="videos.length">
          <li v-for="(item, index) in videos" :key="index">
            <MdItemClassComponent :classInfo="item" />
          </li>
        </ul>
        <p v-else class="no-data">暂无数据</p>
        <!-- 分页 -->
        <div class="pagination">
          <el-pagination @current-change="handleCurrentChange" :current-page="+params.pageNum"
            :page-size="params.pageSize" layout="total, prev, pager, next, jumper" :total="total" background>
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getCategoryList, getVideoListByCategoryId } from '@/axios/course'
import MdItemClassComponent from "../components/class/mdItem/index.vue";
export default {
  name: 'MdPagesCategoryComponent',
  components: {
    MdItemClassComponent
  },
  data() {
    return {
      params: {
        categoryId: '',
        searchValue: '',
        pageNum: 1,
        pageSize: 20
      },
      categoryAryList: [],
      categoryItemInfo: {},
      pIndex: null,
      cIndex: -1,
      videos: [],
      total: 0
    }
  },
  created() {
    if (sessionStorage.getItem('deptId')) {
      console.log(sessionStorage.getItem('deptId'), '---deptId')
      this.params.deptId = sessionStorage.getItem('deptId')
    }

    this.getCategoryList(1)
  },
  methods: {
    disposeData() {
      this.categoryAryList.forEach(item => {
        item.categoryList.unshift({
          rid: -1,
          name: '不限'
        })
      })
      this.params.searchValue=this.$route.query.searchValue ? this.$route.query.searchValue : ''
      this.pIndex = this.$route.query.pId ? this.$route.query.pId : this.categoryAryList[0].rid
      // 存在父级id就找到对应的子级，然后高亮子级
      if (this.$route.query.pId) {
        const cur = this.categoryAryList.filter(item => {
          return item.rid === +this.$route.query.pId
        })
        this.categoryItemInfo = cur[0]
        this.params.categoryId = this.$route.query.cId
      } else {
        this.categoryItemInfo = this.categoryAryList[0]
        this.params.categoryId = this.categoryItemInfo.rid
      }
      console.log(this.categoryItemInfo, 'print')
      this.cIndex = this.$route.query.cId ? this.$route.query.cId : -1
      this.getVideoListByCategoryId(1)
    },
    async getCategoryList() {
        const res = await getCategoryList(1)
        if (res.code === 200) {
          this.categoryAryList = res.data
          this.disposeData()
        }

    },
    handleCategory(item) {
      this.categoryItemInfo = item
      this.pIndex = item.rid
      this.params.categoryId = item.rid
      this.cIndex = -1
      this.getVideoListByCategoryId(1)
    },
    // 根据分类id获取课程详情
    async getVideoListByCategoryId(pNo) {
      this.params.pageNum = pNo
      const res = await getVideoListByCategoryId(this.params)
      if (res.code === 200) {
        this.videos = res.rows
        this.total = res.total || 0
      }
    },
    handleCategoryItem(item) {
      this.cIndex = item.rid
      if (this.cIndex === -1) {
        this.params.categoryId = this.categoryItemInfo.rid
      } else {
        this.params.categoryId = item.rid
      }
      this.getVideoListByCategoryId(1)
    },
    handleCurrentChange(val) {
      this.params.pageNum = val
      this.getVideoListByCategoryId(val)
    }
  }
}
</script>
<style lang="less" scoped>
.md-category-page {
  width: 100%;

  .all {
    display: flex;
    align-items: center;

    >:nth-child(2) {
      margin-left: 8px;
      font-size: 24px;
    }
  }

  .md-cate-box {
    width: 100%;
    background: #f5f7fa;
    padding: 36px 0px;

    .md-cate-panel {
      width: 1152px;
      margin: 0 auto;

      .class-search {
        width: 98%;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        img {
          height: 46px;
        }

        .search-box {
          /deep/ .search-input {
            .el-input__inner {
              border: none;
              width: 240px;
              box-sizing: border-box;
              background: #fff;
              border-radius: 18px;
              border: 1px solid rgba(15, 176, 155, 1);
              height: 36px;
            }

            .el-input__suffix {
              i {
                margin-top: 10px;
                margin-right: 3px;
                font-size: 16px;
                font-weight: bold;
                color: rgba(15, 176, 155, 1);
                cursor: pointer;

                &:hover {
                  color: @md-primary-color;
                }
              }
            }
          }
        }
      }

      .type-box {
        .type-list {
          padding: 10px;
          margin-bottom: 25px;

          b {
            font-size: 14px;
          }

          .type-item {
            color: #1c1f21;
            font-size: 14px;
            line-height: 16px;
            padding: 8px 12px;
            border-radius: 6px;
            margin: 0 12px 12px 0;
            cursor: pointer;
          }

          .type-item-active {
            background: rgba(207, 235, 231, 1);
            color: rgba(15, 176, 155, 1);
          }
        }
      }
    }
  }

  .md-class-con {
    width: 100%;
    background: #F3F5F5;
    max-width: 1920px;
    margin: 0 auto;

    .md-class-container {
      width: 1200px;
      margin: 0 auto;
      padding: 40px 0;

      .md-class-list {
        display: flex;
        flex-wrap: wrap;

        li {
          &:nth-child(4n) {
            .md-item-class-component {
              margin-right: 0;
            }
          }
        }
      }

      .pagination {
        margin-top: 20px;
        display: flex;
        justify-content: center;
      }
    }
  }
}

.no-data {
  color: #999;
  font-size: 12px;
  padding: 30px 0;
  text-align: center;
}

/deep/ .el-pager li.active {
  color: @md-primary-color;
}

/deep/ .el-pager li:hover {
  color: @md-primary-color;
}

/deep/ .el-input__inner:focus {
  border-color: @md-primary-color !important;
}
::v-deep{
  .el-pagination.is-background .el-pager li:not(.disabled).active{
    background-color: #0FB09B;
  }

  .el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li{
    border: 1px solid  #CCCCCC;
    width: 36px;
    height: 36px;
    line-height: 36px;
    border-radius: 6px;
   
  }

  .el-pagination.is-background .el-pager li:not(.disabled):not(.active):hover {
    color: #0FB09B;
  }
  .el-pagination button, .el-pagination span:not([class*=suffix]){
   height: 36px;
   line-height: 36px;
  }
}


</style>
